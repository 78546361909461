import React, { useState } from "react"
import NavHeader from "./NavHeader"
import NavHeaderAccount from "./NavHeaderAccount"
import NavHeaderContents from "./NavHeaderContents"
import NavSidebar from "./NavSidebar"
import NavSidebarAccount from "./NavSidebarAccount"
import NavSidebarContents from "./NavSidebarContents"

interface NavContainerProps {
	children?: React.ReactNode
}

const NavContainer: React.FC<NavContainerProps> = ({ children }) => {
	const [isCollapsed, setIsCollapsed] = useState<boolean>(
		window.innerWidth <= 882,
	)

	const isAccountPage = window.location.pathname.includes("/settings/account")

	const sidebarContents = isAccountPage ? (
		<NavSidebarAccount isCollapsed={isCollapsed} />
	) : (
		<NavSidebarContents isCollapsed={isCollapsed} />
	)

	const headerContents = isAccountPage ? (
		<NavHeaderAccount />
	) : (
		<NavHeaderContents />
	)

	return (
		<div className="flex">
			<NavSidebar
				isCollapsed={isCollapsed}
				setIsCollapsed={setIsCollapsed}
				contents={sidebarContents}
			/>

			<div className="flex h-screen w-full flex-col">
				<NavHeader
					isCollapsed={isCollapsed}
					setIsCollapsed={setIsCollapsed}
					contents={headerContents}
				/>

				{children && <div className="overflow-y-scroll">{children}</div>}
			</div>
		</div>
	)
}

export default NavContainer
