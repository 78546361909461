import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { getSelectedOrg } from "@/utils/utils"
import React, { useState } from "react"

interface PopupFormProps {
	onClose: () => void
	onSubmit: (data: FormData) => void
	email: string
	work: string | undefined
	step: number
}

export interface FormData {
	hearAbout: string
	email: string
	work?: string
	specificSource?: string
}

const PopupForm: React.FC<PopupFormProps> = ({
	onClose,
	onSubmit,
	email: initialEmail,
	work: initialWork,
}) => {
	const account = getSelectedOrg()
	const isOrg = account?.type === "Organization"

	const [formData, setFormData] = useState<FormData>({
		hearAbout: "",
		specificSource: "",
		email: initialEmail,
		work: isOrg ? account.organization_name : initialWork || "",
	})

	const [hearAboutError, setHearAboutError] = useState<string | null>(null)
	const [emailError, setEmailError] = useState("")

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
		field: keyof FormData,
	) => {
		const value = e.target.value
		if (field === "hearAbout") {
			if (value !== "Other") {
				setFormData({ ...formData, hearAbout: value, specificSource: "" })
			} else {
				setFormData({ ...formData, hearAbout: "Other", specificSource: "" })
			}
		} else {
			setFormData({ ...formData, [field]: value })
		}
	}

	const handleSubmit = () => {
		let isValid = true

		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
		if (emailRegex.test(formData.email)) {
			setEmailError("")
		} else {
			setEmailError("Invalid email format")
			isValid = false
		}

		if (
			formData.hearAbout === "Other" &&
			(!formData.specificSource || !/^[a-zA-Z]+$/.test(formData.specificSource))
		) {
			setHearAboutError("Please enter a valid source (e.g., Facebook, Google)")
			isValid = false
		} else {
			setHearAboutError(null)
		}

		if (!isValid) {
			return
		}

		onSubmit(formData)
		onClose()
	}

	return (
		<Dialog open>
			<DialogContent
				style={{
					width: "500px",
					minHeight: "300px",
				}}
			>
				<DialogHeader>
					<DialogTitle className="text-center">
						Complete your signup
					</DialogTitle>
				</DialogHeader>
				<DialogDescription>
					<div className="mb-4">
						<label
							aria-label="Email input"
							htmlFor="user-email"
							className="block py-2 font-medium text-gray-700"
						>
							Primary Email <span className="text-red-500">*</span>
						</label>
						<input
							id="user-email"
							className="block w-full rounded-md border p-2 text-center focus:outline-none"
							type="email"
							value={formData.email}
							onChange={e => {
								handleChange(e, "email")
							}}
							required
						/>
						{emailError && (
							<span className="mt-1 text-xs text-red-500">{emailError}</span>
						)}
						<div className="mb-2 mt-2 text-xs">
							Please <strong>confirm</strong> the email address we should use
							for subscription-related communication. We{" "}
							<strong>recommend</strong> using a work email.
						</div>

						<hr className="my-4" />

						<label
							aria-label="Work input"
							htmlFor="work"
							className="block py-2 font-medium text-gray-700"
						>
							{isOrg ? "Company Name" : "Where do you work?"}{" "}
							<span className="text-red-500">*</span>
						</label>
						<input
							id="work"
							className="block w-full rounded-md border p-2 text-center focus:outline-none"
							type="text"
							value={formData.work || ""}
							onChange={e => {
								handleChange(e, "work")
							}}
						/>
						<div className="mb-2 mt-2 text-xs">
							{isOrg
								? "Let us know your company (if applicable) to tailor our services for you."
								: "Share where you work (if applicable) to help us understand your background."}
						</div>

						<hr className="my-4" />

						<label
							aria-label="How did you hear about us?"
							htmlFor="hear-about"
							className="block font-medium text-gray-700"
						>
							How did you hear about us?
						</label>
						<select
							id="hear-about"
							className="block w-full rounded-md border p-2 text-center"
							value={formData.hearAbout}
							onChange={e => {
								handleChange(e, "hearAbout")
							}}
							required
						>
							<option value="">-- Select --</option>
							<option value="Open Source Project">Open Source Project</option>
							<option value="Stack Overflow">Stack Overflow</option>
							<option value="Referred">Referred by someone</option>
							<option value="Google Search">Search(Google)</option>
							<option value="Bing Search">Search(Bing)</option>
							<option value="Twitter">Twitter</option>
							<option value="LinkedIn">LinkedIn</option>
							<option value="Instagram">Instagram</option>
							<option value="Facebook">Facebook</option>
							<option value="Reddit">Reddit</option>
							<option value="YouTube">YouTube</option>
							<option value="Other">Other</option>
						</select>

						{formData.hearAbout === "Other" && (
							<div className="my-4">
								<label
									aria-label="Specific source input"
									htmlFor="other-source"
									className="block font-medium text-gray-700"
								>
									Specific Source <span className="text-red-500">*</span>
								</label>
								<input
									id="other-source"
									className="block w-full rounded-md border p-2 focus:outline-none"
									type="text"
									placeholder="Please specify"
									onChange={e => {
										handleChange(e, "specificSource")
									}}
									maxLength={25}
									required
									value={formData.specificSource}
								/>
								{hearAboutError && (
									<span className="mt-2 text-sm text-red-500">
										{hearAboutError}
									</span>
								)}
							</div>
						)}

						<button
							className="my-4 rounded bg-crb-primary px-4 py-2 text-white"
							onClick={handleSubmit}
						>
							Submit
						</button>
					</div>
				</DialogDescription>
			</DialogContent>
		</Dialog>
	)
}

export default PopupForm
