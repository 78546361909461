import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { Book } from "lucide-react"
import { Link } from "react-router-dom"
import z from "zod"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { trpc } from "@/trpc"
import { zodResolver } from "@hookform/resolvers/zod"
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx"

const formSchema = z.object({
	userToken: z.string().min(1, {
		message: "User token is required.",
	}),
})

interface AzureBotUserModalProps {
	readonly open: boolean
	readonly onOpenChange: (open: boolean) => void
}

const AzureBotUserModal: React.FC<AzureBotUserModalProps> = ({
	open,
	onOpenChange,
}) => {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			userToken: "",
		},
	})

	const saveAzureUserQuery = trpc.providers.saveOrgUser.useMutation({
		onSuccess: resp => {
			if (resp.success) {
				form.reset({
					userToken: "",
				})
				onOpenChange(false)
			} else {
				toast.error("Failed to save Azure DevOps user details")
			}
		},
		onError: () => {
			toast.error("Failed to save Azure DevOps user details")
		},
	})

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		saveAzureUserQuery.mutate({
			userToken: values.userToken,
		})
	}

	useEffect(() => {
		if (open) {
			form.reset()
		}
	}, [open])

	return (
		<>
			<Dialog open={open}>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
						<DialogContent
							showCloseIcon={false}
							onPointerDownOutside={e => {
								e.preventDefault()
							}}
							className="sm:max-w-lg"
						>
							<DialogHeader>
								<DialogTitle>Azure DevOps integration</DialogTitle>
							</DialogHeader>
							<p className="text-sm text-muted-foreground">
								Connect CodeRabbit to your Azure DevOps API using a Personal
								Access Token from your organization’s user for seamless
								integration.
							</p>
							<div className="">
								<FormField
									control={form.control}
									name="userToken"
									render={({ field }) => (
										<FormItem>
											<FormLabel className="text-sm">
												Personal Access Token
											</FormLabel>
											<FormControl>
												<Input placeholder="Enter token..." {...field} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							<div className="mt-3 flex w-full justify-between">
								<Button variant="outline" asChild>
									<Link
										target="_blank"
										to="https://docs.coderabbit.ai/platforms/saas-azure-devops"
									>
										<Book size={16} className="mr-2" />
										Documentation
									</Link>
								</Button>
								<Button
									type="submit"
									onClick={async () => {
										await form.handleSubmit(onSubmit)()
									}}
									disabled={saveAzureUserQuery.isLoading}
								>
									Submit
								</Button>
							</div>
						</DialogContent>
					</form>
				</Form>
			</Dialog>
		</>
	)
}

export default AzureBotUserModal
