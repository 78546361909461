import React from "react"

interface ButtonOutlinedProps {
	readonly title: string
	readonly onClick?: () => void
	readonly disabled?: boolean
	readonly type?: "button" | "reset" | "submit"
	readonly image?: React.ReactNode
}

const ButtonOutlined: React.FC<ButtonOutlinedProps> = ({
	title,
	onClick,
	disabled,
	type,
	image,
}) => {
	return (
		<button
			type={type}
			onClick={onClick}
			disabled={disabled}
			className={`flex items-center rounded-lg border-[1px] border-[#FF4702] px-4 py-1.5
				text-sm font-semibold text-[#FF4702] transition focus:outline-none focus:ring-4
				focus:ring-[#FF470252] enabled:hover:bg-[#fff0e9] disabled:border-gray-300
				disabled:text-gray-300 dark:focus:ring-[#ff8e6252]`}
		>
			{image && image} {title}
		</button>
	)
}

export default ButtonOutlined
