import AzureLogin from "@/pages/Login/AzureLogin.tsx"
import ApplicationConsent from "@/pages/Onboarding/ApplicationConsent.tsx"
import AzureUser from "@/pages/Settings/AzureUser/AzureUser.tsx"
import GitLabUser from "@/pages/Settings/GitlabUser/GitlabUser.tsx"
import { Navigate, Route, Routes } from "react-router-dom"
import ProtectedRoutes from "./ProtectedRoutes"
import { axiosInterceptor } from "./axios-interceptor"
import { useFirebaseCtx } from "./firebase"
import { GrafanaProvider } from "./grafana"
import NotFound from "./pages/404"
import Dashboard from "./pages/Dashboard"
import InstallationMessage from "./pages/InstallationMessage/InstallationMessage"
import Integrations from "./pages/Integrations/Integrations"
import { InviteSelfHostedAdmin } from "./pages/InviteSelfHostedAdmin"
import Login from "./pages/Login"
import GitHubSelfHostedLogin from "./pages/Login/GitHubSelfHostedLogin"
import GitLabSelfHostedLogin from "./pages/Login/GitLabSelfHostedLogin"
import { SignInWithEmailLink } from "./pages/Login/SignInWithEmailLink"
import Onboarding from "./pages/Onboarding"
import Profile from "./pages/Profile"
import ApiKeys from "./pages/Settings/ApiKeys/ApiKeys"
import Configure from "./pages/Settings/Configuration/Configure"
import DiscordAppInstall from "./pages/Settings/CustomReporting/Discord/DiscordAppInstall"
import NewRecurringReport from "./pages/Settings/CustomReporting/NewRecurringReport"
import RecurringReports from "./pages/Settings/CustomReporting/RecurringReports"
import SlackAppInstall from "./pages/Settings/CustomReporting/Slack/SlackAppInstall"
import RepositoryList from "./pages/Settings/Repository/RepositoryList"
import SeatManagement from "./pages/Settings/SeatManagement/SeatManagement"
import { Learnings } from "./pages/learnings"
import { useProvider } from "./utils/providers"

const ENABLED_GRAFANA = import.meta.env.VITE_ENABLE_GRAFANA === "true"
axiosInterceptor()

function App() {
	const { isSelfHosted, isCRSelfHosted } = useProvider()
	const { isAuthenticated: isFirebaseUser, overrideRole } = useFirebaseCtx()

	return (
		<>
			<Routes>
				{/* Login pages */}
				<Route path="/" element={<Navigate to="/login" />} />
				<Route path="login" element={<Login />} />
				<Route path="sign-in-with-email" element={<SignInWithEmailLink />} />
				<Route
					path="sign-in-with-email/:orgName"
					element={<SignInWithEmailLink />}
				/>
				<Route path="login-azure" element={<AzureLogin />} />
				{import.meta.env.VITE_ENABLE_CODERABBIT_SELF_HOSTED && (
					<Route
						path="login/coderabbit-self-hosted"
						element={<Login isCRSelfHostedLogin />}
					/>
				)}
				{import.meta.env.VITE_ENABLE_GITHUB_SELF_HOSTED && (
					<Route
						path="login/github-self-hosted"
						element={<GitHubSelfHostedLogin />}
					/>
				)}
				<Route
					path="login/gitlab-self-hosted"
					element={<GitLabSelfHostedLogin />}
				/>

				{/* Dashboard pages */}
				<Route
					path="dashboard"
					element={
						<>
							{ENABLED_GRAFANA ? (
								<GrafanaProvider>
									<ProtectedRoutes Component={Dashboard} />
								</GrafanaProvider>
							) : (
								<ProtectedRoutes Component={Dashboard} />
							)}
						</>
					}
				/>

				{/* Repositories and repo settings pages */}
				{!isFirebaseUser && (
					<>
						<Route
							path="settings/repositories"
							element={<ProtectedRoutes Component={RepositoryList} />}
						/>
						<Route
							path="repository/:id/settings"
							element={<ProtectedRoutes Component={Configure} />}
						/>
						<Route
							path="installation-success"
							element={<ProtectedRoutes Component={InstallationMessage} />}
						/>
					</>
				)}
				{(overrideRole?.toLowerCase() === "super_admin" ||
					sessionStorage.getItem("override_role")?.toLowerCase() ===
						"super_admin") && (
					<Route
						path="invite-self-hosted-admin"
						element={<ProtectedRoutes Component={InviteSelfHostedAdmin} />}
					/>
				)}
				<Route
					path="learnings"
					element={<ProtectedRoutes Component={Learnings} />}
				/>

				{/* Integration pages */}
				<Route path="integrations" element={<Integrations />} />

				{/* Reporting pages */}
				{import.meta.env.VITE_FEATURE_REPORTING_ENABLED && (
					<>
						{/* <Route
						path="reports/ondemand"
						element={<ProtectedRoutes Component={OnDemandReports} />}
					/> */}
						<Route
							path="reports/recurring"
							element={<ProtectedRoutes Component={RecurringReports} />}
						/>
						<Route
							path="reports/recurring/new"
							element={<ProtectedRoutes Component={NewRecurringReport} />}
						/>
						<Route
							path="reports/recurring/:reportID"
							element={<ProtectedRoutes Component={NewRecurringReport} />}
						/>
						<Route
							path="/slack-notifications-install"
							element={<SlackAppInstall />}
						/>
						<Route
							path="/discord-notifications-install"
							element={<DiscordAppInstall />}
						/>
					</>
				)}

				{/* Org settings pages */}
				<Route
					path="settings/organization"
					element={<ProtectedRoutes Component={Configure} />}
				/>
				{import.meta.env.VITE_FEATURE_API_KEYS_ENABLED && (
					<Route
						path={
							isSelfHosted ? "settings/account/api-keys" : "settings/api-keys"
						}
						element={<ProtectedRoutes Component={ApiKeys} />}
					/>
				)}
				<Route
					path={
						isSelfHosted
							? "settings/account/gitlab-user"
							: "settings/gitlab-user"
					}
					element={<ProtectedRoutes Component={GitLabUser} />}
				/>

				<Route
					path={
						isSelfHosted ? "settings/account/azure-user" : "settings/azure-user"
					}
					element={<ProtectedRoutes Component={AzureUser} />}
				/>

				{/* Subscription pages */}
				<Route
					path={
						isSelfHosted || isCRSelfHosted
							? "settings/account/subscription"
							: "settings/subscription"
					}
					element={<ProtectedRoutes Component={SeatManagement} />}
				/>
				<Route
					path="subscription"
					element={
						<Navigate
							to={
								isSelfHosted || isCRSelfHosted
									? "/settings/account/subscription"
									: "/settings/subscription"
							}
						/>
					}
				/>
				{isSelfHosted && (
					<Route
						path="settings/subscription"
						element={<Navigate to={"/settings/account/subscription"} />}
					/>
				)}

				{/* Onboarding pages */}
				<Route path="/onboarding" element={<Onboarding />} />
				<Route path="/application-consent" element={<ApplicationConsent />} />

				{/* Profile page */}
				<Route
					path="profile"
					element={<ProtectedRoutes Component={Profile} />}
				/>

				{/* Misc pages */}
				<Route path="*" element={<NotFound />} />
			</Routes>
		</>
	)
}

export default App
