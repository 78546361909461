import React from "react"
import { useLocation } from "react-router"
import "../Referral/GrowSurf.css"
import DesktopOrgMenu from "./DesktopOrgMenu.js"
import MenuItem from "./MenuItem.js"
import { BottomNavItems, NavItems } from "./Nav.js"
import UserSidebarItem from "./UserSidebarItem.js"
import { checkIsActive, isNotHidden } from "./nav-utils.js"

interface NavSidebarContentsProps {
	isCollapsed: boolean
}

const NavSidebarContents: React.FC<NavSidebarContentsProps> = ({
	isCollapsed,
}) => {
	const { pathname } = useLocation()

	return (
		<>
			<div className="mt-3 hidden w-full px-2 md:block">
				<DesktopOrgMenu isCollapsed={isCollapsed} />
				<div className="my-4 w-full border-b" />
			</div>

			{/* Menu Items */}
			<div
				className={`flex h-full flex-col justify-between scrollbar-thin ${
					isCollapsed ? "items-center" : "items-start"
				} mt-6 px-3 md:mt-0`}
			>
				<nav className={`flex-1 ${isCollapsed ? "" : "w-full"}`}>
					{NavItems.filter(isNotHidden).map(item => (
						<MenuItem
							key={item.name}
							name={item.name}
							Icon={item.Icon}
							link={item.link}
							newTab={!!item.newTab}
							items={item.items}
							isActive={checkIsActive(item, pathname)}
							isCollapsed={isCollapsed}
						/>
					))}
				</nav>

				<nav className={`mb-4 ${isCollapsed ? "" : "w-full"}`}>
					{BottomNavItems.filter(isNotHidden).map(item => (
						<MenuItem
							key={item.name}
							name={item.name}
							Icon={item.Icon}
							link={item.link}
							newTab={!!item.newTab}
							items={item.items}
							isActive={checkIsActive(item, pathname)}
							isCollapsed={isCollapsed}
						/>
					))}
				</nav>

				<UserSidebarItem isCollapsed={isCollapsed} />
			</div>
		</>
	)
}

export default NavSidebarContents
