import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbPage,
	BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Card, CardContent } from "@/components/ui/card.tsx"
import { Form as F } from "@/components/ui/form.tsx"
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@/components/ui/tabs.tsx"
import type {
	CRHandlerUpdateOrgLevelSettingsBody,
	CRHandlerUpdateOrgLevelSettingsResp,
	CRHandlerUpdateReposSettingsBody,
	CRHandlerUpdateReposSettingsResp,
} from "@/typings/coderabbitHandler.ts"
import { sentryCaptureException } from "@/utils/utils.ts"
import type Form from "@rjsf/core"
import type { IChangeEvent } from "@rjsf/core"
import type { AxiosResponse } from "axios"
import axios from "axios"
import {
	createRef,
	useCallback,
	useEffect,
	useMemo,
	useState,
	type FC,
} from "react"
import { useNavigate } from "react-router-dom"
import RightIcon from "../../../assets/right-icon.svg"
import Loader from "../../../components/Loader/Loader"
import NavContainer from "../../../components/Nav/NavContainer"
import SettingsToggleSwitch from "../../../components/ToggleSwitch/SettingsToggleSwitch"
import { ConfigProvider, useConfigCtx } from "./context"
import {
	AutoReviewSettingsForm,
	ChatSettingsForm,
	GeneralSettingsForm,
	KnowledgeBaseSettingsForm,
	ReviewSettingsForm,
	ToolsSettingsForm,
} from "./schemaForm/SchemaForm"

import { cn } from "@/lib/utils"
import { useProvider } from "@/utils/providers"
import type { ConfigSettings } from "@coderabbitai/schemas"
import { CircularProgress } from "@mui/material"
import type { ErrorObject } from "ajv"
import Ajv from "ajv"
import { toast } from "react-toastify"
import { SaveConfigMessage } from "./SaveConfigMessage"
import { SchemaErrors } from "./SchemaErrors"
import schemaV2 from "./schema/schema.v2.json"
import { useTabChange } from "./useTabChange"

type HandleReviewFormsChange = <T extends "auto_review" | "tools">(
	key: T,
	data: ConfigSettings["reviews"][T],
) => void

const ajv = new Ajv()
const validateWithJsonSchema = ajv.compile(schemaV2)

const tabList = [
	{
		title: "General",
		id: "generalSettings",
	},
	{
		title: "Review",
		id: "reviewSettings",
	},
	{
		title: "Chat",
		id: "chatSettings",
	},
	{
		title: "Knowledge Base",
		id: "knowledgeBaseSettings",
	},
] as const

const reviewsSubTabsList = [
	{
		title: "Settings",
		id: "reviewSettingsInner",
	},
	{
		title: "Auto Review",
		id: "autoReview",
	},
	{
		title: "Tools",
		id: "tools",
	},
] as const

// Repo/Org settings page
const ConfigWithoutCtx: React.FC = () => {
	const {
		setConfig,
		config: savedConfig,
		initialConfig,
		selectedOrg,
		getDefaultValues,
		isRepoSettings,
		isDefaultSettings,
		repo,
		id: repoID,
		doesOrgSettingsExist,
		isLoading,
		setRepoSettingsEnabled,
		setIsDefaultSettings,
		form,
	} = useConfigCtx()

	useEffect(() => {
		getDefaultValues()
	}, [repoID])

	const navigate = useNavigate()

	const watch = form.watch()

	const getRepoSettingsSaveBody = (): CRHandlerUpdateReposSettingsBody => {
		return {
			repo_id: repoID,
			org_id: selectedOrg?.id,
			provider: provider ? provider : "github",
			enabled: !watch.enabled,
			settings: savedConfig,
		}
	}

	const getOrgSettingSaveBody = (): CRHandlerUpdateOrgLevelSettingsBody => {
		return {
			organization_id: selectedOrg?.id,
			dataOptOut: !watch.dataOptOut,
			settings: savedConfig,
		}
	}

	const { provider } = useProvider()

	const saveRepoSettings = (
		authToken: string,
		body: CRHandlerUpdateReposSettingsBody,
	) => {
		return axios.put<
			CRHandlerUpdateReposSettingsResp,
			AxiosResponse<CRHandlerUpdateReposSettingsResp>,
			CRHandlerUpdateReposSettingsBody
		>(`${import.meta.env.VITE_CODERABBIT_FUNC_URL}/updateReposSettings`, body, {
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
			params: {
				organization_id: selectedOrg?.id,
			},
		})
	}

	const saveOrgSettings = (
		authToken: string,
		body: CRHandlerUpdateOrgLevelSettingsBody,
	) => {
		return axios.put<
			CRHandlerUpdateOrgLevelSettingsResp,
			AxiosResponse<CRHandlerUpdateOrgLevelSettingsResp>,
			CRHandlerUpdateOrgLevelSettingsBody
		>(
			`${import.meta.env.VITE_CODERABBIT_FUNC_URL}/updateOrgLevelSettings`,
			body,
			{
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				params: {
					organization_id: selectedOrg?.id,
				},
			},
		)
	}

	const generalSettingsFormRef = createRef<Form>()
	const reviewSettingsFormRef = createRef<Form>()
	const toolsSettingsFormRef = createRef<Form>()
	const autoReviewSettingsFormRef = createRef<Form>()
	const knowledgeBaseSettingsFormRef = createRef<Form>()
	const chatSettingsFormRef = createRef<Form>()

	const [isLoadingApplyChanges, setIsLoadingApplyChanges] = useState(false)
	const [schemaError, setSchemaError] = useState<
		ErrorObject[] | null | undefined
	>(null)

	const handleSaveSettings = () => {
		const authToken = sessionStorage.getItem("accessToken")

		if (!authToken) {
			sessionStorage.clear()
			navigate("/login")
			return
		}

		setIsLoadingApplyChanges(true)

		const body = isRepoSettings
			? getRepoSettingsSaveBody()
			: getOrgSettingSaveBody()

		const isValid = validateWithJsonSchema(body.settings)

		if (!isValid) {
			setIsLoadingApplyChanges(false)
			setSchemaError(validateWithJsonSchema.errors)

			toast.error("Schema validation failed")
			return
		}

		;(isRepoSettings
			? saveRepoSettings(authToken, body as CRHandlerUpdateReposSettingsBody)
			: saveOrgSettings(authToken, body as CRHandlerUpdateOrgLevelSettingsBody)
		)
			.then(() => {
				setIsLoadingApplyChanges(false)
				setSchemaError(null)

				if (isDefaultSettings) {
					form.reset({ ...watch, enabled: false })
					setRepoSettingsEnabled(true)
				} else {
					form.reset({ ...watch })
				}

				setIsDefaultSettings(false)

				toast.success(
					`${isRepoSettings ? "Repository" : "Organization"} settings updated!`,
				)

				initialConfig.current = savedConfig
			})
			.catch(err => {
				setIsLoadingApplyChanges(false)
				console.error(err)

				toast.error("Something went wrong, Please try again.")

				if (isRepoSettings) {
					sentryCaptureException("updateReposSettings: API failed: ", err)
				} else {
					sentryCaptureException("updateOrgLevelSettings: API failed: ", err)
				}
			})
	}

	const {
		reviews: { tools, auto_review, ...reviews },
		knowledge_base,
		chat,
		...generalDefault
	} = useMemo(() => savedConfig, [savedConfig])

	const onChangeForm = useCallback(
		(e: IChangeEvent) => {
			setConfig(prev => ({
				...prev,
				...e.formData,
			}))
		},
		[setConfig],
	)

	const handleReviewFormsChange = useCallback<HandleReviewFormsChange>(
		(key, data) => {
			setConfig(prev => ({
				...prev,
				reviews: {
					...prev.reviews,
					[key]: data,
				},
			}))
		},
		[setConfig],
	)

	// Land on same tab after refresh
	const { tabID, onChangeTab } = useTabChange("generalSettings")
	const { tabID: reviewTabId, onChangeTab: onChangeReviewTabs } = useTabChange(
		"reviewSettingsInner",
		"reviewTab",
	)

	return (
		<NavContainer>
			{isLoading ? (
				<Loader />
			) : (
				<F {...form}>
					<div className="relative w-full px-2 py-10 sm:px-16">
						{isRepoSettings && (
							<Breadcrumb className="mb-2">
								<BreadcrumbList>
									<BreadcrumbItem>
										<BreadcrumbLink href="/settings/repositories">
											Repositories
										</BreadcrumbLink>
									</BreadcrumbItem>
									<BreadcrumbSeparator>
										<img src={RightIcon} />
									</BreadcrumbSeparator>
									<BreadcrumbItem>
										<BreadcrumbPage>{repo}</BreadcrumbPage>
									</BreadcrumbItem>
								</BreadcrumbList>
							</Breadcrumb>
						)}
						<>
							<div className="flex w-full flex-col items-start gap-5">
								<div className="flex w-full flex-col flex-wrap justify-between gap-4 sm:flex-row">
									<div className="flex flex-1 items-center gap-2 lg:gap-5">
										<span className="font-500 mb-2 flex flex-col font-inter text-2xl not-italic leading-7 text-[#242424] lg:text-2xl">
											{isRepoSettings ? repo : "Organization Settings"}

											{!isRepoSettings && (
												<span className="font-400 mr-4 mt-4 flex w-fit items-center justify-center font-inter text-sm leading-5 text-muted-foreground">
													You can configure settings applicable to the entire
													organization. Settings configured at the repository
													level will override these.
												</span>
											)}

											{isRepoSettings &&
												isDefaultSettings &&
												doesOrgSettingsExist && (
													<span className="font-400 mr-4 mt-4 flex w-fit items-center justify-center font-inter text-sm leading-5 text-muted-foreground">
														Repository settings are not configured. Organization
														level settings are being used. You can configure
														custom settings for this repository below.
													</span>
												)}

											{isRepoSettings &&
												!isDefaultSettings &&
												!doesOrgSettingsExist && (
													<span className="font-400 mr-4 mt-4 flex w-fit items-center justify-center font-inter text-sm leading-5 text-muted-foreground">
														Repository settings are configured and active.
													</span>
												)}

											{isRepoSettings &&
												isDefaultSettings &&
												!doesOrgSettingsExist && (
													<span className="font-400 mr-4 mt-4 flex w-fit items-center justify-center font-inter text-sm leading-5 text-muted-foreground">
														Repository settings are not configured. If you have
														a 'coderabbit.yaml' file in your repository, its
														settings will take precedence and be used.
													</span>
												)}
										</span>
									</div>

									<div className="flex flex-col justify-center sm:flex-grow sm:flex-row">
										<div className="ml-auto flex-shrink-0">
											<Button
												className="relative min-w-28"
												size="sm"
												onClick={() => {
													generalSettingsFormRef.current?.submit()
													reviewSettingsFormRef.current?.submit()
													knowledgeBaseSettingsFormRef.current?.submit()
													chatSettingsFormRef.current?.submit()
													toolsSettingsFormRef.current?.submit()
													autoReviewSettingsFormRef.current?.submit()

													handleSaveSettings()
												}}
												disabled={isLoadingApplyChanges}
											>
												{isLoadingApplyChanges ? (
													<CircularProgress
														size={20}
														className="color-secondary"
													/>
												) : (
													"Apply Changes"
												)}
											</Button>
										</div>
									</div>
								</div>

								{isRepoSettings &&
									!isDefaultSettings &&
									doesOrgSettingsExist && (
										<div className="flex w-full flex-col">
											<SettingsToggleSwitch
												label="Use Organization Settings"
												name="enabled"
												text="Organization settings will be applied. If disabled, the repository-specific settings configured below will be used."
												control={form.control}
												onChange={(newState: boolean) => {
													setRepoSettingsEnabled(!newState)
												}}
											/>
										</div>
									)}

								{schemaError ? (
									<div className="space-y-4">
										<SchemaErrors errors={schemaError} />
									</div>
								) : (
									<div className="space-y-4">
										<SaveConfigMessage
											{...{
												initialConfig: initialConfig.current,
												config: savedConfig,
											}}
										/>
									</div>
								)}

								<Tabs
									defaultValue={tabID ?? "generalSettings"}
									className="w-full"
								>
									<TabsList className="mb-3">
										{tabList.map(tab => (
											<TabsTrigger
												key={tab.id}
												value={tab.id}
												onClick={() => {
													onChangeTab(tab)
												}}
											>
												{tab.title}
											</TabsTrigger>
										))}
									</TabsList>
									<TabsContent value="generalSettings" className="w-full">
										<Card>
											<CardContent className="pt-3">
												<GeneralSettingsForm
													{...{
														formData: generalDefault,
														formRef: generalSettingsFormRef,
														onChange: onChangeForm,
													}}
												/>

												{!isRepoSettings && (
													<div
														className={cn(
															selectedOrg?.role !== "admin"
																? "pointer-events-none"
																: undefined,
															"max-w-screen-md",
														)}
													>
														<SettingsToggleSwitch
															control={form.control}
															label="Fine-tune Your Reviews"
															name="dataOptOut"
															text="CodeRabbit will learn from your usage and get better over time. Your data remains secure, isolated, and is used only for fine-tuning your reviews. We recommend opting in. If you decide to opt out, your data will not be stored. If you opt out after opting in, all existing learnings will be removed from the system."
															onChange={(newState: boolean) => {
																form.setValue("dataOptOut", newState)
															}}
														/>
													</div>
												)}
											</CardContent>
										</Card>
									</TabsContent>
									<TabsContent value="reviewSettings" className="w-full">
										<Card>
											<CardContent className="pt-3">
												<Tabs
													defaultValue={reviewTabId ?? "reviewSettingsInner"}
													className="w-full"
												>
													<TabsList className="mb-3">
														{reviewsSubTabsList.map(tab => (
															<TabsTrigger
																key={tab.id}
																value={tab.id}
																onClick={() => {
																	onChangeReviewTabs(tab)
																}}
															>
																{tab.title}
															</TabsTrigger>
														))}
													</TabsList>
													<TabsContent
														value="reviewSettingsInner"
														className="w-full"
													>
														<ReviewSettingsForm
															{...{
																formData: { reviews },
																formRef: reviewSettingsFormRef,
																onChange: e => {
																	setConfig(prev => ({
																		...prev,
																		reviews: {
																			...prev.reviews,
																			...e.formData.reviews,
																		},
																	}))
																},
															}}
														/>
													</TabsContent>
													<TabsContent value="autoReview" className="w-full">
														<AutoReviewSettingsForm
															{...{
																formData: { auto_review },
																formRef: autoReviewSettingsFormRef,
																onChange: e => {
																	handleReviewFormsChange(
																		"auto_review",
																		e.formData.auto_review,
																	)
																},
															}}
														/>
													</TabsContent>
													<TabsContent value="tools" className="w-full">
														<ToolsSettingsForm
															{...{
																formData: { tools },
																formRef: toolsSettingsFormRef,
																onChange: e => {
																	handleReviewFormsChange(
																		"tools",
																		e.formData.tools,
																	)
																},
															}}
														/>
													</TabsContent>
												</Tabs>
											</CardContent>
										</Card>
									</TabsContent>
									<TabsContent value="knowledgeBaseSettings" className="w-full">
										<Card>
											<CardContent className="pt-3">
												<KnowledgeBaseSettingsForm
													{...{
														formData: { knowledge_base },
														formRef: knowledgeBaseSettingsFormRef,
														onChange: onChangeForm,
													}}
												/>
											</CardContent>
										</Card>
									</TabsContent>
									<TabsContent value="chatSettings" className="w-full">
										<Card>
											<CardContent className="pt-3">
												<ChatSettingsForm
													{...{
														formData: { chat },
														formRef: chatSettingsFormRef,
														onChange: onChangeForm,
													}}
												/>
											</CardContent>
										</Card>
									</TabsContent>
								</Tabs>
							</div>
						</>
					</div>
				</F>
			)}
		</NavContainer>
	)
}

const Configure: FC = () => (
	<ConfigProvider>
		<ConfigWithoutCtx />
	</ConfigProvider>
)

export default Configure
