import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Typography from "@mui/material/Typography"
import type { AxiosResponse } from "axios"
import axios from "axios"
import { useState } from "react"
import { toast } from "react-toastify"
import type { BillingHandlerCreatePortalSessionResp } from "../../typings/billingHandler"
import type {
	CRHandlerUpgradePlanBody,
	CRHandlerUpgradePlanResp,
} from "../../typings/coderabbitHandler"
import ButtonOutlined from "../ButtonOutlined/ButtonOutlined"
import ButtonRegular from "../ButtonRegular/ButtonRegular"

const style = {
	position: "absolute" as const,
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	borderRadius: 5,
}

export default function SeatManagementModal(props: any) {
	const { isOpen, setIsOpen, plan, quantity, subscriptionID } = props
	const [selectedPlan, setSelectedPlan] = useState(plan ? plan : "month")
	const [noOfSeats, setNoOfSeats] = useState(quantity ? quantity : 0)
	const handleClose = () => setIsOpen(false)

	const handlePlanChange = (e: any) => {
		setSelectedPlan(e.target.value)
	}

	const handleChange = (e: any) => {
		setNoOfSeats(e.target.value)
	}

	const redirectChargebeePortal = async () => {
		await axios
			.get<BillingHandlerCreatePortalSessionResp>(
				`${
					import.meta.env.VITE_BILLING_FUNC_URL
				}/createPortalSession?customer_id=${sessionStorage.getItem("org_id")}`,
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem("auth-token")}`,
					},
				},
			)
			.then(response => {
				const data = response.data
				window.location.assign(data.data.access_url)
			})
			.catch(error => {
				console.error(error)
				toast("Unable to redirect to the billing portal. Please try again.", {
					position: "top-right",
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: "",
					theme: "colored",
					type: "error",
				})
			})
	}

	const handleSubmit = () => {
		axios
			.put<
				CRHandlerUpgradePlanResp,
				AxiosResponse<CRHandlerUpgradePlanResp>,
				CRHandlerUpgradePlanBody
			>(
				`${import.meta.env.VITE_CODERABBIT_FUNC_URL}/upgradePlan`,
				{
					chargebee_subscription_id: subscriptionID,
					quantity: Number(noOfSeats),
					plan_id:
						selectedPlan == "year"
							? "CRB_ANNUAL_SUBSCRIPTION_PER_SEAT-USD-Yearly"
							: "CRB_MONTHLY_SUBSCRIPTION_PER_SEAT-USD-Monthly",
				},
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
					},
					params: {
						organization_id: sessionStorage.getItem("org_id"),
					},
				},
			)
			.then(res => {
				if (res.status == 200) {
					void redirectChargebeePortal()
				}
			})
			.catch(error => {
				console.error(error)
				toast.error(
					"An error occurred while updating the seat management. Please try again.",
				)
			})
	}

	return (
		<div>
			<Modal
				open={isOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
						className="font-poppins"
					>
						Upgrade Subscription
					</Typography>
					<Typography
						id="modal-modal-description margin-top-5"
						sx={{ mt: 2 }}
						className="font-poppins"
					>
						Please confirm the number of seats and subscription period.
					</Typography>
					<Typography
						id="modal-modal-description margin-top-5"
						sx={{ mt: 2 }}
						className="font-poppins"
					>
						Number of Seats
					</Typography>
					<input
						type="text"
						className="form-input w-full rounded-lg border border-gray-300 p-2"
						placeholder="Number of seats"
						onChange={handleChange}
						value={noOfSeats}
					/>
					<Typography id="modal-modal-description margin-top-5" sx={{ mt: 2 }}>
						Subscription Period
					</Typography>
					<select
						id="ignored_branches"
						name="ignored_branches"
						value={selectedPlan}
						onChange={handlePlanChange}
						className="form-select w-full rounded-lg border border-gray-300 px-2 py-2"
					>
						<option value={"year"} key={"Yearly"}>
							Yearly
						</option>
						<option value={"month"} key={"Monthly"}>
							Monthly
						</option>
					</select>
					<div className="mt-5 flex justify-end gap-3">
						<ButtonOutlined title="Close" type="button" onClick={handleClose} />
						<div className="flex flex-row">
							<ButtonRegular
								title="Proceed"
								type="button"
								onClick={handleSubmit}
							/>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
