import CircularProgress from "@mui/material/CircularProgress"
import React from "react"
import LogoFull from "../../svg/logo-full"
import "./Loader.css"

interface LoaderProps {
	readonly message?: string
}

const Loader: React.FC<LoaderProps> = ({ message }) => {
	return (
		<div className="page-loader">
			<div className="mx-auto flex w-10/12 flex-col items-center sm:w-full">
				<LogoFull width={350} />
				<CircularProgress
					style={{ color: /* crb-primary */ "#FF4702", marginTop: "10px" }}
					size={40}
				/>
				{message && (
					<h1 className="mt-4 font-poppins text-lg text-[#242424] sm:text-xl">
						{message}
					</h1>
				)}
			</div>
		</div>
	)
}

export default Loader
