import Loader from "@/components/Loader/Loader"
import NavContainer from "@/components/Nav/NavContainer"
import Search from "@/components/Search/Search"
import { Accordion } from "@/components/ui/accordion.js"
import { DataTable } from "@/components/ui/data-table"
import { trpc } from "@/trpc.js"
import { sentryCaptureException } from "@/utils/utils.js"
import type { PaginationState } from "@tanstack/react-table"
import {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	type FC,
} from "react"
import { toast } from "react-toastify"
import { columns } from "./Columns.js"
import { LearningFilters, type ChipsInfo } from "./components/LearningFilter.js"
import { createPineconeFilter } from "./utils.js"

type Texts<T extends string> = {
	[key in T]: string
}

type LearningText = Texts<"description" | "loadingMessage" | "title">
interface LearningsProps {
	texts?: LearningText
}

interface SimilaritySearchInput {
	text: string
	topK: number
}

const defaultTexts: LearningText = {
	title: "Learnings",
	description: `
	By opting in, CodeRabbit will utilize and store insights from your interactions
	to enhance its learning over time. This process allows CodeRabbit to deliver increasingly
	refined and personalized assistance. Below, you'll find learnings generated across various repositories.
	`,
	loadingMessage: "Setting up Learnings... 🚀",
}

export const Learnings: FC<LearningsProps> = ({ texts = defaultTexts }) => {
	const { isLoading, ...query } = trpc.learnings.getAllLearnings.useQuery()
	const learnings = useMemo(() => query.data?.data ?? [], [query.data?.data])

	const {
		mutate,
		data: similaritySearch,
		isLoading: isSimilarityLoading,
	} = trpc.learnings.similaritySearch.useMutation()
	const filteredLearnings = useMemo(
		() => similaritySearch?.data ?? [],
		[similaritySearch],
	)

	const [similaritySearchQuery, setSimilaritySearch] =
		useState<SimilaritySearchInput>({
			text: "",
			topK: 10,
		})

	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	})

	const [filter, setTableData] = useState<typeof learnings | []>(learnings)
	useEffect(() => {
		// keep the filter in sync with the learnings
		setTableData(learnings)
	}, [learnings])

	const [chips, setChips] = useState<ChipsInfo[]>([])

	const pagesVisited = useMemo(
		() => pagination.pageIndex * pagination.pageSize,
		[pagination],
	)

	const lastPaginationBeforeSearch = useRef<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	})

	// Remove pagination when search query is present.
	useEffect(() => {
		if (
			(similaritySearchQuery.text.trim() !== "" || chips.length) &&
			pagination.pageIndex !== 0
		) {
			lastPaginationBeforeSearch.current = pagination
			setPagination({ pageIndex: 0, pageSize: 10 })
		}

		if (
			similaritySearchQuery.text.trim() === "" &&
			pagination.pageIndex === 0 &&
			!chips.length
		) {
			setPagination(lastPaginationBeforeSearch.current)
		}
	}, [similaritySearchQuery, chips])

	const learningToShow = useMemo(
		() =>
			filter.length && similaritySearchQuery.text.trim() === ""
				? filter.slice(pagesVisited, pagesVisited + pagination.pageSize)
				: filteredLearnings.slice(
						pagesVisited,
						pagesVisited + pagination.pageSize,
					),
		[pagesVisited, pagination, filteredLearnings, filter],
	)

	const total = useMemo(
		() =>
			filter.length && similaritySearchQuery.text.trim() === ""
				? filter.length
				: filteredLearnings.length,
		[filter, filteredLearnings],
	)
	const pageCount = useMemo(
		() =>
			Math.ceil(
				(similaritySearchQuery.text.length ? filteredLearnings.length : total) /
					pagination.pageSize,
			),
		[similaritySearchQuery, filteredLearnings, total, pagination.pageSize],
	)

	const search = useCallback(
		(input: SimilaritySearchInput = similaritySearchQuery) => {
			const filter = createPineconeFilter(chips)

			mutate(
				{
					text: input.text,
					topK: input.topK,
					matchThreshold: 0.2,
					filter,
				},
				{
					onError: error => {
						toast.error("Failed to search learnings")
						sentryCaptureException("Failed to search learnings", error)
					},
				},
			)
		},
		[chips],
	)

	return (
		<NavContainer>
			{isLoading ? (
				<Loader message={texts.loadingMessage} />
			) : (
				<div className="container mx-auto px-8 pb-2 pt-7">
					<div className="w-full">
						<div className="flex items-center justify-between">
							<div>
								<div className="font-500 mb-2 font-inter text-2xl leading-8 text-foreground">
									{texts.title}
								</div>
								<div className="font-400 max-w-lg font-inter text-sm leading-5 text-muted-foreground">
									{texts.description}
								</div>
							</div>
						</div>
						<div className="mb-1 mt-6 grid w-full grid-cols-[1fr_auto] gap-3 md:grid-cols-[1fr_auto_1fr]">
							<Search
								onSearch={text => {
									setSimilaritySearch(({ topK }) => ({ text, topK }))
									search({
										text,
										topK: similaritySearchQuery.topK
											? similaritySearchQuery.topK
											: 10,
									})
								}}
								debounce
								label="Similarity Search"
								inputProps={{
									className: "w-full",
								}}
								fullWidth
							/>
							<div>
								<div className="font-poppins text-sm text-crb-text-secondary">
									Top K
								</div>
								<input
									type="number"
									placeholder="Top K"
									value={similaritySearchQuery.topK}
									onChange={e => {
										setSimilaritySearch(({ text }) => ({
											text,
											topK: parseInt(e.target.value),
										}))
									}}
									aria-label="Search"
									className="
										w-16 flex-1 rounded-md border border-gray-300 bg-white py-2 pl-4 pr-1
						font-figtreeRegular shadow-sm transition placeholder:text-gray-400 focus:outline-none focus:ring-4
						focus:ring-gray-300"
								/>
							</div>
							<div className="mt-2">
								<LearningFilters<(typeof learnings)[number]>
									{...{
										setTableData,
										learnings,
										useChips: [chips, setChips],
										isSimilaritySearch: !!similaritySearchQuery.text.trim(),
										similaritySearchQuery: search,
									}}
								/>
							</div>
						</div>
						<Accordion type="single" collapsible className="w-full">
							<DataTable
								hideTableHeader
								data={learningToShow}
								columns={columns()}
								tableOptions={{
									manualPagination: true,
									pageCount,
									state: {
										pagination,
									},
									onPaginationChange: setPagination,
								}}
								tableWrapperProps={{
									className: "border-hidden",
								}}
								tableRowProps={{
									className: "border-hidden",
								}}
								tableCellProps={{
									className: "p-0 bg-transparent py-1",
								}}
								isLoading={isSimilarityLoading}
							/>
						</Accordion>
					</div>
				</div>
			)}
		</NavContainer>
	)
}
