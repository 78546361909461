import { firebaseAuth, SESSION_STORAGE_TENANT_ID } from "@/firebase"
import { logger } from "@/lib/utils"

export async function logout(): Promise<void> {
	const isFirebase = !!sessionStorage.getItem(SESSION_STORAGE_TENANT_ID)

	if (isFirebase) {
		sessionStorage.clear()
		await firebaseAuth.signOut().catch(() => {
			logger.error("Failed to sign out firebase, redirecting to login")
		})

		logger.info("Signed out firebase")
		window.location.assign("/sign-in-with-email")

		return
	}

	sessionStorage.clear()
	window.location.assign("/login")
}
