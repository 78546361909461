import ImageButton from "@/components/ImageButton/ImageButton"
import Search from "@/components/Search/Search"
import { Button } from "@/components/ui/button"
import { DataTable } from "@/components/ui/data-table"
import { useColumnFilter } from "@/components/ui/data-table-utils"
import { trpc } from "@/trpc"
import type { RowSelectionState } from "@tanstack/react-table"
import React, { useEffect, useMemo, useState } from "react"
import { FaRegTrashAlt } from "react-icons/fa"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"
import CreateReportImage from "../../../assets/create-report.svg"
import Loader from "../../../components/Loader/Loader"
import DestructiveActionModal from "../../../components/Modal/DestructiveActionModal"
import NavContainer from "../../../components/Nav/NavContainer"
import { columns } from "./Columns"
const RecurringReports: React.FC = () => {
	const navigate = useNavigate()

	const listReports = trpc.reporting.listReports.useQuery()
	const reportList = listReports.data?.reports ?? []

	const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
	const selectedRows = useMemo(
		() => Object.keys(rowSelection).map(index => reportList[Number(index)]?.id),
		[rowSelection, reportList],
	)

	const { columnFilters, setColumnFilters, setColumnFilter } =
		useColumnFilter<(typeof reportList)[number]>()

	const [deleteReportIDs, setDeleteReportIDs] = useState<string[]>()

	const [hasSubscription, setHasSubscription] = useState<boolean>(true)

	const deleteReports = trpc.reporting.deleteReports.useMutation({
		onSuccess: ({ data }) => {
			setRowSelection({})
			setDeleteReportIDs(undefined)
			listReports.remove()
			void listReports.refetch().finally(() => {
				if (data.successfulDeletions === 0) {
					toast.error("Failed to delete the report(s)")
				} else if (data.failedDeletions === 0) {
					toast.success("Report(s) deleted successfully")
				} else {
					toast.warning(data.message)
				}
			})
		},
	})

	useEffect(() => {
		if (listReports.error) {
			if (listReports.error.data?.httpStatus === 401) {
				setHasSubscription(false)
			} else {
				toast.error(
					"Failed to fetch the list of reports: " + listReports.error.message,
				)
			}
		}
	}, [listReports.error])

	useEffect(() => {
		if (deleteReports.error) {
			toast.error(
				"Failed to delete the report(s): " + deleteReports.error.message,
			)
		}
	}, [deleteReports.error])

	const isLoading = listReports.isLoading || deleteReports.isLoading

	return (
		<NavContainer>
			{isLoading && <Loader />}

			<div className="container mx-auto px-8 pb-2 pt-7">
				<div className="container mx-auto">
					<div>
						<div className="mb-4 flex items-center justify-between">
							<div className="mb-3 flex flex-wrap items-center gap-3">
								<div className="font-500 font-inter text-2xl leading-8 text-foreground">
									Recurring
								</div>
							</div>

							{reportList.length > 0 && (
								<ImageButton
									title="Create Report"
									onClick={() => {
										navigate("./new")
									}}
								/>
							)}
						</div>

						{reportList.length === 0 && (
							<div className="flex items-center justify-center rounded-md border">
								<div className="flex flex-col items-center justify-center px-6 py-20 text-center">
									<img src={CreateReportImage} alt="Create Report" />
									<h2 className="font-500 mt-3 text-lg text-foreground">
										{hasSubscription
											? "Create your first report"
											: "Reports are available on the Pro plan only"}
									</h2>
									<div className="text-muted-foreground">
										{hasSubscription
											? "Let's tell your data's story together!"
											: "Please upgrade to the Pro plan to access this feature"}
									</div>
									<div className="mt-4">
										{hasSubscription ? (
											<ImageButton
												title="Create Report"
												onClick={() => {
													navigate("./new")
												}}
											/>
										) : (
											<Button
												onClick={() => {
													navigate("/settings/subscription")
												}}
											>
												Upgrade to Pro
											</Button>
										)}
									</div>
								</div>
							</div>
						)}
					</div>

					{reportList.length > 0 && (
						<div>
							<div className="mb-4 flex items-center justify-between gap-4">
								<Search
									onSearch={query => {
										setColumnFilter("name", query)
										setRowSelection({})
									}}
									showLabel={false}
								/>
								{selectedRows.length > 0 && (
									<button
										className="flex items-center gap-2 rounded-lg px-3 py-2 text-red-500 hover:bg-red-100"
										title="Delete"
										onClick={() => {
											setDeleteReportIDs(selectedRows.map(String))
										}}
									>
										<FaRegTrashAlt /> Delete selected
									</button>
								)}
							</div>
							<div className="block max-w-full overflow-x-auto overflow-y-hidden">
								<DataTable
									data={reportList}
									columns={columns({
										onDeleteReport: report => {
											setDeleteReportIDs([report.id])
										},
									})}
									tableOptions={{
										onRowSelectionChange: setRowSelection,
										onColumnFiltersChange: setColumnFilters,
										state: {
											rowSelection,
											columnFilters,
										},
									}}
								/>
							</div>

							<DestructiveActionModal
								active={!!deleteReportIDs}
								title="Delete Report"
								description="Are you sure you want to delete the selected report(s)?"
								onCancel={() => {
									setDeleteReportIDs(undefined)
								}}
								onConfirm={() => {
									if (deleteReportIDs && deleteReportIDs.length > 0) {
										deleteReports.mutate(deleteReportIDs)
									}
								}}
							/>
						</div>
					)}
				</div>
			</div>
		</NavContainer>
	)
}

export default RecurringReports
