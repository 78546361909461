import { Button } from "@/components/ui/button.tsx"
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx"
import { Input } from "@/components/ui/input.tsx"
import { Label } from "@/components/ui/label.tsx"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group.tsx"
import GitlabUserDescription from "@/pages/Settings/GitlabUser/GitlabUserDescription.tsx"
import { trpc } from "@/trpc.ts"
import { useProvider } from "@/utils/providers"
import { zodResolver } from "@hookform/resolvers/zod"
import { Book } from "lucide-react"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import z from "zod"
import Loader from "../../../components/Loader/Loader"
import NavContainer from "../../../components/Nav/NavContainer"

const formSchema = z.object({
	userToken: z.string().min(1, {
		message: "User token is required.",
	}),
})

const GitLabUser: React.FC = () => {
	const gitlabUserQuery = trpc.providers.getOrgUser.useQuery()
	const gitlabUser = gitlabUserQuery.data
	const { isGitlabCloud, isGitlabSelfHosted } = useProvider()

	const gitlabUserPreferenceQuery =
		trpc.providers.getOrgGitlabUserPreference.useQuery()
	const gitlabUserPreference = gitlabUserPreferenceQuery.data

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			userToken: "",
		},
	})

	const [userChoice, setUserChoice] = useState<string>(
		gitlabUserPreference || isGitlabSelfHosted
			? "organization-user"
			: "default",
	)

	const saveGitlabUserPreferenceQuery =
		trpc.providers.updateOrgGitlabUserPreference.useMutation({
			onSuccess: resp => {
				if (resp.success) {
					toast.success("GitLab user details saved successfully")

					form.reset({
						userToken: "",
					})
				} else {
					toast.error("Failed to save GitLab user usage preference")
				}
			},
			onError: () => {
				toast.error("Failed to save GitLab user usage preference")
			},
		})

	const saveGitlabUserQuery = trpc.providers.saveOrgUser.useMutation({
		onSuccess: resp => {
			if (resp.success) {
				if (isGitlabCloud) {
					saveGitlabUserPreferenceQuery.mutate({
						useCustomUser: true,
					})
				}

				gitlabUserQuery.remove()
				void gitlabUserQuery.refetch()
			} else {
				toast.error("Failed to save GitLab user details")
			}
		},
		onError: () => {
			toast.error("Failed to save GitLab user details")
		},
	})

	useEffect(() => {
		setUserChoice(
			gitlabUserPreference || isGitlabSelfHosted
				? "organization-user"
				: "default",
		)
	}, [gitlabUserPreference, isGitlabSelfHosted])

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		saveGitlabUserQuery.mutate({
			userToken: values.userToken,
		})
	}

	return (
		<NavContainer>
			{gitlabUserQuery.isLoading && <Loader />}

			<div className="container mx-auto px-8 pb-2 pt-7">
				<div className="container mx-auto">
					<div>
						<div className="flex justify-between">
							<div>
								<div className="font-500 mb-2 font-inter text-xl leading-8 text-foreground">
									GitLab user
								</div>
								<GitlabUserDescription />
							</div>
							<Button variant="outline" size="sm" asChild>
								<Link
									to={
										isGitlabCloud
											? "https://docs.coderabbit.ai/integrations/saas-gitlab"
											: "https://docs.coderabbit.ai/integrations/self-hosted-gitlab#creating-coderabbit-user"
									}
									target="_blank"
								>
									<Book size={16} className="mr-2" />
									Documentation
								</Link>
							</Button>
						</div>

						<div className="mt-3 flex justify-between rounded-md border p-4">
							<div>
								<div className="font-500 font-inter leading-5 text-foreground">
									Settings
								</div>
								<p className="text-sm font-light text-muted-foreground">
									Configure GitLab user
								</p>
							</div>
							<div className="w-1/2">
								<Form {...form}>
									<form
										onSubmit={e => {
											e.preventDefault()
										}}
										className="space-y-4"
									>
										<RadioGroup
											defaultValue="default"
											value={userChoice}
											onValueChange={value => {
												setUserChoice(value)
											}}
										>
											{isGitlabCloud ? (
												<>
													<div className="space-x-2 p-2">
														<div className="flex items-center">
															<RadioGroupItem
																value="default"
																id="default-user"
																className="mr-2 text-crb-primary"
															/>
															<Label htmlFor="default-user">Default User</Label>
														</div>
														<p className="mt-2 pl-4 text-xs text-muted-foreground">
															The CodeRabbit default user is already set up in
															GitLab SaaS and will be added to your project when
															you install the CodeRabbit app.
														</p>
													</div>
													<hr />
												</>
											) : null}
											<div className="space-x-2 p-2">
												<div className="flex items-center">
													{isGitlabCloud ? (
														<RadioGroupItem
															value="organization-user"
															id="organization-user"
															className="mr-2 text-crb-primary"
														/>
													) : null}
													<Label
														htmlFor="organization-user"
														className={isGitlabSelfHosted ? "ml-6" : ""}
													>
														Organization user (User ID:{" "}
														{gitlabUser ? (
															<span className="text-crb-primary">
																{gitlabUser}
															</span>
														) : (
															"None"
														)}
														)
													</Label>
												</div>
												<p className="mt-2 pl-4 text-xs text-muted-foreground">
													To interact with the GitLab API, a Personal Access
													Token is required. We recommend creating a new user
													named "CodeRabbit" and generating a Personal Access
													Token from this user.
													<Link
														to="https://docs.coderabbit.ai/platforms/saas-gitlab#recommendations"
														target="_blank"
														className="ml-1 text-crb-primary hover:underline"
													>
														Learn more
													</Link>
												</p>
											</div>
										</RadioGroup>
										<div className="m-0 pl-8">
											{userChoice === "organization-user" ||
											isGitlabSelfHosted ? (
												<FormField
													control={form.control}
													name="userToken"
													render={({ field }) => (
														<FormItem>
															<FormLabel className="text-sm">
																Personal Access Token
															</FormLabel>
															<FormControl>
																<Input
																	disabled={userChoice !== "organization-user"}
																	placeholder="Enter token..."
																	{...field}
																/>
															</FormControl>
															<FormMessage />
															<p className="-mt-1 text-xs text-muted-foreground">
																Please note that MRs made by this user will not
																get reviewed.
															</p>
														</FormItem>
													)}
												/>
											) : null}
											<Button
												type="submit"
												className="mt-3"
												size="sm"
												onClick={async () => {
													const currentToken = form.getValues("userToken")
													if (userChoice !== "organization-user") {
														saveGitlabUserPreferenceQuery.mutate({
															useCustomUser: false,
														})
													} else if (
														isGitlabCloud &&
														!currentToken &&
														gitlabUser
													) {
														saveGitlabUserPreferenceQuery.mutate({
															useCustomUser: true,
														})
													} else {
														await form.handleSubmit(onSubmit)()
													}
												}}
												disabled={
													saveGitlabUserQuery.isLoading ||
													saveGitlabUserPreferenceQuery.isLoading ||
													!userChoice
												}
											>
												Save
											</Button>
										</div>
									</form>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</NavContainer>
	)
}

export default GitLabUser
