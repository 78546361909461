import NavContainer from "@/components/Nav/NavContainer"
import { Button } from "@/components/ui/button"
import { Form, FormControl, FormItem } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import { logger } from "@/lib/utils"
import { trpc } from "@/trpc"
import { EMAIL_REGEX } from "@/utils/constant"
import { Copy } from "lucide-react"
import { useState, type FC } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"

interface CreateSelfHostedAccount {
	email: string
	name: string
	tenantName: string
	hostUrl: string
}

const GIT_PROVIDERS = [
	{
		label: "GitHub Self Hosted",
		value: "github-self-hosted",
	},
	{
		label: "GitHub",
		value: "github",
	},
	{
		label: "GitLab Self Hosted",
		value: "gitlab-self-hosted",
	},
	{
		label: "GitLab",
		value: "gitlab",
	},
	{
		label: "Azure DevOps",
		value: "azure-devops",
	},
	{
		label: "Azure DevOps Self Hosted",
		value: "azure-devops-self-hosted",
	},
	{
		label: "Bitbucket",
		value: "bitbucket",
	},
	{
		label: "Bitbucket Self Hosted",
		value: "bitbucket-self-hosted",
	},
]

export const InviteSelfHostedAdmin: FC = () => {
	const form = useForm<CreateSelfHostedAccount>()
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = form

	const { mutate, isLoading, isError, data } =
		trpc.superAdmin.inviteAdmin.useMutation()

	const [gitProvider, setGitProvider] = useState("")

	const onSubmit = (data: CreateSelfHostedAccount) => {
		if (!gitProvider.trim().length) {
			toast.error("Please select a git provider")
			return
		}
		mutate(
			{
				...data,
				gitProvider,
			},
			{
				onError: error => {
					logger.error("Error inviting user: ", error)
					toast.error(`Error inviting user: ${error.message}`)
				},
				onSuccess: res => {
					if (!res.isSuccess) {
						logger.error(`Error inviting user: ${res.message}`)
						toast.error(`Error inviting user: ${res.message}`)
						return
					}
					logger.info(`Invite sent to ${data.email}`)
					toast.success(`Invite sent to ${data.email}`)
				},
			},
		)
	}

	const errArr = Object.values(errors)

	return (
		<NavContainer>
			<div className="container mx-auto px-8 pb-2 pt-7">
				<div className="w-full">
					<div className="flex flex-col gap-2">
						<div className="font-500 mb-2 font-inter text-2xl leading-8 text-foreground">
							Invite Self-Hosted User
						</div>
						<div className="font-400 max-w-lg font-inter text-sm leading-5 text-muted-foreground">
							Invite a self-hosted user to join CodeRabbit.
						</div>
						<div className="mx-auto flex flex-col gap-2 lg:w-[50%]">
							{errArr.map((error, index) => (
								<div
									key={index}
									className="border-l-4 border-red-500 bg-red-100 px-4 py-2 font-poppins text-red-700"
									role="alert"
								>
									<p className="text-sm">{error.message}</p>
								</div>
							))}
						</div>

						<Form {...form}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="mx-auto flex max-w-[600px] flex-col items-center justify-center gap-2 rounded-md bg-gray-50 px-4 py-8 drop-shadow-md">
									<Input
										id="email"
										type="email"
										placeholder="Email"
										{...register("email", {
											required: "Email is required",
											pattern: {
												value: EMAIL_REGEX,
												message: "Invalid email address",
											},
										})}
									/>
									<Input
										id="name"
										type="text"
										placeholder="Name"
										{...register("name", {
											required: "Name is required",
										})}
									/>
									<Input
										id="tenantName"
										type="text"
										placeholder="Tenant Name / Instance Name / Organization Name eg: CodeRabbitai"
										{...register("tenantName", {
											required: "Tenant Name is required",
										})}
									/>
									<Input
										id="hostUrl"
										type="text"
										placeholder="Host Url eg: https://example.com"
										{...register("hostUrl", {
											required: "Host Url is required",
										})}
									/>
									<div className="w-full">
										<FormItem>
											<Select
												name="gitProvider"
												onValueChange={(value: string) => {
													setGitProvider(value)
												}}
												defaultValue={gitProvider}
											>
												<FormControl>
													<SelectTrigger>
														<SelectValue placeholder="Git Provider" />
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													{GIT_PROVIDERS.map(({ label, value }, i) => {
														return (
															<SelectItem value={value} key={i}>
																{label}
															</SelectItem>
														)
													})}
												</SelectContent>
											</Select>
										</FormItem>
									</div>

									<Button
										type="submit"
										disabled={isLoading && !isError}
										className="mt-4"
									>
										Submit
									</Button>
								</div>
							</form>
						</Form>

						{data?.isSuccess &&
						data.data?.details.subscription.self_hosted_instance_id ? (
							<div className="mx-auto flex max-w-[600px] flex-col gap-5 pt-10">
								<FormResponseAndNextStepInstructions
									value={data.data.details.subscription.self_hosted_instance_id}
									title="Self Hosted Instance ID"
									instructions="Copy the self hosted instance ID and use it to create a subscription in chargebee"
								/>
								<FormResponseAndNextStepInstructions
									value={data.data.tenantId}
									title="Tenant ID"
									instructions="Copy the tenant ID and add it to the license key payload"
								/>
							</div>
						) : null}

						{data?.isSuccess && (
							<div>
								<p>Json Response:</p>
								<pre className="max-h-[600px] overflow-auto rounded-md bg-gray-100 p-4 text-sm">
									{JSON.stringify(data, null, 2) || "No data"}
								</pre>
							</div>
						)}
					</div>
				</div>
			</div>
		</NavContainer>
	)
}

interface FormResponseAndNextStepInstructionsProps {
	value: string
	title: string
	instructions: string
}

const FormResponseAndNextStepInstructions: FC<
	FormResponseAndNextStepInstructionsProps
> = ({ value, title, instructions }) => {
	return (
		<div className="flex space-x-2">
			<div className="grid flex-1 gap-1">
				<Label htmlFor="link" className="sr-only">
					{title}
				</Label>
				<Input id="link" value={value} readOnly />
				<div className="mx-1 text-xs text-muted-foreground">{instructions}</div>
			</div>
			<Button
				type="submit"
				size="sm"
				className="mt-0.5 px-3"
				onClick={() => {
					navigator.clipboard
						.writeText(value)
						.then(() => {
							toast.success("API Key copied to clipboard")
						})
						.catch(error => {
							toast.error("Failed to copy API Key: " + error)
						})
				}}
			>
				<span className="sr-only">Copy</span>
				<Copy className="h-4 w-4" />
			</Button>
		</div>
	)
}
